class UserUtils {
    isNullOrUndefined(value) {
      return value === null || value === undefined;
    }
    //check if is a string, if not, convert it, else, return itself
    convertToString(value) {
      if (typeof value === 'string') {
        return value;
      }
      return value.toString();
    }
    //check if is a number, if not, convert it, else, return itself
    convertToNumber(value) {
      if (typeof value === 'number') {
        return value;
      }
      return parseInt(value);
    }
    
}

module.exports = UserUtils;
